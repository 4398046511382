<template>
    <div class="footer center">
        <div class="external-links">
<!--            <el-row :gutter="30">-->
<!--                <el-col :span="4">-->
<!--                    <p><a href="http://www.mnr.gov.cn" target="_blank" title="中华人民共和国自然资源部">自然资源部</a></p>-->
<!--                </el-col>-->
<!--                <el-col :span="4">-->
<!--                    <p><a href="http://www.landchina.com" target="_blank" title="中国土地市场网">中国土地市场网</a></p>-->
<!--                </el-col>-->
<!--            </el-row>-->
          <p><a href="https://zrzy.rerc.com.cn:19018/#/login" target="_blank" title="自然资源确权登记成果公开展示服务系统">自然资源确权登记成果公开展示服务系统</a></p>
        </div>
        <div class="content-box">
            <p class="p-text1">
               <!-- <img src="../../assets/images/footer/blue.png" style="vertical-align:text-bottom;"> -->
                <span>联系电话：010-66558800</span>|
                <span>传真：010-66558844</span>
            </p>
            <p>自然资源部不动产登记中心版权所有 Copyright © 2003 - 2021 , All Rights Reserved .</p>
            <p class="p-text2">
                京ICP备
                <a target="_blank" href="https://beian.miit.gov.cn/">12039414号-1 </a>
                <img src="../../assets/images/footer/beian.png" style="vertical-align:text-bottom;">
              <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010202009023">京公网安备11010202009023</a>
            </p>
        </div>
    </div>
</template>
<script>
    export default {

    }
</script>
<style lang="scss">
    .footer{
        font-size: 13px;
        width:100%!important;
        border-top: 3px solid #3569a0;
        background-color: #e8eff6;
        margin-top: 10px!important;
        padding: 0px 0px 10px 0px;
        a{
            cursor: pointer;
            color: #2f2f2f;
        }
        a:hover {
            color: #ef4545;
        }
        .external-links{
            p{
                text-align: center;
                line-height: 30px;
                margin-bottom: 0px;
                color: #696363;
            }
        }
        .content-box{
            margin-top: 5px;
            p{
                text-align: center;
                line-height: 30px;
                margin-bottom: 0px;
                color: #696363;
                span{
                    padding:0px 14px;
                    color: #696363;
                }
            }
            .p-text1{
                img{
                  width: 80px;
                  height:80px;
                }
            }
            .p-text2{
                padding-bottom:0px;
                img{
                    width: 20px;
                    height:20px;
                }
            }
        }
    }
</style>
